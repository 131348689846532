import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import { ContentWrapper } from "../../assets/styles/base";
import MetaComponents from "../../components/seo/MetaComponents";
import GreyHeader from "../../components/GreyHeader";
import { SUCCESS_STORIES_LIST } from "../../constants/successStoriesList";
import StoryCard from "../../components/successStories/StoryCard";

const Index = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <GreyHeader title="Erfahrungen und Berichte unserer Kunden." />
            <ContentWrapper>
                <StoriesList>
                    {Object.keys(SUCCESS_STORIES_LIST).map((key, index) => {
                        return (
                            <li key={index}>
                                <StoryCard data={SUCCESS_STORIES_LIST[key]} />
                            </li>
                        );
                    })}
                </StoriesList>
            </ContentWrapper>
        </Layout>
    );
};

const StoriesList = styled.ul`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(315px, auto));
    justify-content: center;
    grid-gap: 30px;
    list-style: none;
    margin: 50px auto 0;
    padding: 0;
    flex-wrap: wrap;
    width: 100%;

    @media (min-width: 810px) {
        justify-content: start;
    }
`;

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["header", "footer", "page.index"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
