import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const StoryCard = ({ data }) => {
    const { anaUndPaulStoryCardImage, danielaStoryCardImage, beateStoryCardImage } = useStaticQuery(graphql`
        query {
            anaUndPaulStoryCardImage: file(relativePath: { eq: "success-stories/images/mobile/anna-und-paul.jpeg" }) {
                childImageSharp {
                    gatsbyImageData(formats: [AUTO, WEBP, AVIF])
                }
            }
            danielaStoryCardImage: file(relativePath: { eq: "success-stories/images/mobile/daniela.jpeg" }) {
                childImageSharp {
                    gatsbyImageData(formats: [AUTO, WEBP, AVIF])
                }
            }
            beateStoryCardImage: file(relativePath: { eq: "success-stories/images/mobile/beate.jpeg" }) {
                childImageSharp {
                    gatsbyImageData(formats: [AUTO, WEBP, AVIF])
                }
            }
        }
    `);
    const getStoryCardImage = (data) => {
        let image;
        switch (data.name) {
            case "Anna und Paul":
                image = getImage(anaUndPaulStoryCardImage);
                break;
            case "Daniela":
                image = getImage(danielaStoryCardImage);
                break;
            default:
                image = getImage(beateStoryCardImage);
                break;
        }
        return image;
    };
    return (
        <Wrapper>
            <a href={data.url}>
                <GatsbyImage image={getStoryCardImage(data)} alt={data.name} />
            </a>
            <Header>
                <h3>
                    <a href={data.url}>{data.name}</a>
                </h3>
                <Date>{data.date}</Date>
                <Arrow href={data.url}>
                    <img src="/svg/arrow-right.svg" height="18" alt="Pfeil" />
                </Arrow>
            </Header>
            <p>{data.storyShort}</p>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    background: #fff;
    box-sizing: border-box;
    color: #111827;
    margin: 0 0 30px;
    position: relative;
    width: 350px;
    max-width: 100%;

    h3 {
        font-size: 13px;
        font-weight: 700;
        margin: 16px 0 2px;

        a {
            color: #374151;
        }
    }
    p {
        font-size: 13px;
        line-height: 24px;
        margin: 16px 0 0;
    }
`;

const Header = styled.div`
    padding-right: 50px;
    position: relative;
`;

const Image = styled.img`
    display: block;
    width: 100%;
`;

const Date = styled.div`
    color: #6b7280;
    font-size: 11px;
`;

const Arrow = styled.a`
    margin-top: -20px;
    padding: 8px 6px 8px 8px;
    position: absolute;
    right: 0;
    top: 50%;
`;

export default StoryCard;
