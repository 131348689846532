export const SUCCESS_STORIES_LIST = {
    AnnaUndPaul: {
        name: "Anna und Paul",
        date: "24.11.2021",
        storyShort:
            "Auf der Suche nach einer Finanzierung für ein Haus am Land, das sie zum Wohnsitz ihrer gerade gegründeten Familie machen wollten, wurde der Kreditantrag eines jungen Paars von mehr als nur einer Bank abgelehnt. Erst miracl half ihnen, ein Kreditinstitut zu finden und ließ ihren Traum Wirklichkeit werden.",
        image: {
            preview: "/success-stories/images/preview/anna-und-paul.jpeg",
            mobile: "/success-stories/images/mobile/anna-und-paul.jpeg",
            desktop: "/success-stories/images/desktop/anna-und-paul.jpeg",
        },
        url: "/unsere-kunden/anna-und-paul/",
    },
    Daniela: {
        name: "Daniela",
        date: "02.03.2022",
        storyShort:
            "Nach der Trennung von ihrem Partner will eine alleinerziehende Mutter den gemeinsamen Hauskredit auf sich alleine übertragen. Selbst ihre Hausbank lehnt ab. Miracl hält an einer winzigen Erfolgschance fest und verschafft ihr schließlich sogar niedrigere Raten.",
        image: {
            preview: "/success-stories/images/preview/daniela.jpeg",
            mobile: "/success-stories/images/mobile/daniela.jpeg",
            desktop: "/success-stories/images/desktop/daniela.jpeg",
        },
        url: "/unsere-kunden/daniela/",
    },
    Beate: {
        name: "Beate",
        date: "10.04.2022",
        storyShort:
            "Als sie gerade dabei sind, ein gemeinsamen Haus zu bauen, wird eine Selbstständige Ende 50 von ihrem Partner verlassen. Eine Bank für die weitere Finanzierung zu finden, scheint aussichtslos, doch mit miracl findet sich eine Lösung.",
        image: {
            preview: "/success-stories/images/preview/beate.jpeg",
            mobile: "/success-stories/images/mobile/beate.jpeg",
            desktop: "/success-stories/images/desktop/beate.jpeg",
        },
        url: "/unsere-kunden/beate/",
    },
};
